// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:35:30+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            5c3c9c74dc3989a86ae0a5975fb3c3f4
//    Signature:      eyJpdiI6InpWcUh5ZUFxb25vbXNMRnA5NkZub3c9PSIsInZhbHVlIjoiZ3ByamJVeHNiMVdDWlh1SG5DajJwaHBSSXdYM1g1aUhtOVJEbjVmV0VQVW9kNW8yUEdFdTFPMEtIWkU2ck1HdjBwdHVsM3c0Z01tZXlVbUczUHc1RXBab2pNMmEwZlQwU2RyNTNrUEJUdmtJWnprNWhSTHNMYzJ3WnB5NlJSa1RsUE0zYlNEK1hscnFGK1wvcmN2YVlDYkNCclJrOVNUK1BCbzN6QW4yODBWSm5LWWpKQ1RcL0Z6ZmI0RGhLTlE5UWR5MlEzeHdBemdnczVheHplaWZYazlRNWZPbW5PNTMzeTN0ditFWVFkREdtNzVkbG93d04zellvems0elo1N3ZpZ1piV1UyWTBtNHZ2TWVKcDR0WFFYMk5scFBCemtQb2YwcVB4dmxcL282eWtITU54OTlMdnVHbitwMEsyb1hKOG1WY1FUWTZrcmlkdU9UUkVcL0VONm9xVFZaYTZIV2NJajJWTUtTTjc4aGhmb3BTSVVHb2xSWlJrdXhGYXBDVjErMHM0cXA5Mis0YTBHQVBmNmVKMStPTVE9PSIsIm1hYyI6ImU1NDJjOTk5ZDMwZDYwZmViYjNmY2I3NTRkMWQ5NzhjZWMwZjg4MDA4ZDY1Yjc4NzI4MzE5N2FjNTNhZjA5YWQifQ==
class CartNotification extends HTMLElement {
  constructor() {
    super();

    this.notification = document.getElementById('cart-notification');
    this.header = document.querySelector('sticky-header');
    this.onBodyClick = this.handleBodyClick.bind(this);
    
    this.notification.addEventListener('keyup', (evt) => evt.code === 'Escape' && this.close());
    this.querySelectorAll('button[type="button"]').forEach((closeButton) =>
      closeButton.addEventListener('click', this.close.bind(this))
    );
  }

  open() {
    this.notification.classList.add('animate', 'active');

    this.notification.addEventListener('transitionend', () => {
      this.notification.focus();
      trapFocus(this.notification);
    }, { once: true });

    document.body.addEventListener('click', this.onBodyClick);
  }

  close() {
    this.notification.classList.remove('active');

    document.body.removeEventListener('click', this.onBodyClick);

    removeTrapFocus(this.activeElement);
  }

  renderContents(parsedState) {
      this.cartItemKey = parsedState.key;
      this.getSectionsToRender().forEach((section => {
        document.getElementById(section.id).innerHTML =
          this.getSectionInnerHTML(parsedState.sections[section.id], section.selector);
      }));

      // if (this.header) this.header.reveal();
      this.open();
  }

  getSectionsToRender() {
    return [
      {
        id: 'cart-notification-product',
        selector: `[id="cart-notification-product-${this.cartItemKey}"]`,
      },
      {
        id: 'cart-notification-button'
      },
      {
        id: 'cart-icon-bubble'
      }
    ];
  }

  getSectionInnerHTML(html, selector = '.shopify-section') {
    debugger
    return new DOMParser()
      .parseFromString(html, 'text/html')
      .querySelector(selector).innerHTML;
  }

  handleBodyClick(evt) {
    const target = evt.target;
    
    const beamWidget = document.querySelector('#beam-nonprofit-widget-notification');
	const isNonprofitSelected = Number(beamWidget.dataset.isNonprofitSelected);       
    
    if (target !== this.notification && !target.closest('cart-notification')) {
      const disclosure = target.closest('details-disclosure, header-menu');
      this.activeElement = disclosure ? disclosure.querySelector('summary') : null;
      this.close();
    }
  }

  setActiveElement(element) {
    this.activeElement = element;
  }
}

customElements.define('cart-notification', CartNotification);
